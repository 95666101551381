import { useContext, useEffect, useRef, useState } from "react";
import merge from "lodash/merge";
import { applyThemeWithLegacy } from "../../utils/theme/theme-handler";
import {
    shouldSignIn,
    removeNonVisible,
    tokenResolver,
    versionResolver,
    registerDebugUtils,
    applicationResolver,
    isSpecimenVersion,
    getSpecimenVariation,
    flattenBooleansInObject,
} from "../../utils/init";
import { axiosClient } from "../../utils/axios-client/axios-client";
import * as localStorage from "../../utils/localStorage";
import { v4 as uuid } from "uuid";
import { configStore } from "../../contexts/ConfigContext";
import { contentPagesStore } from "../../contexts/ContentPagesContext";
import { dataStore } from "../../contexts/DataContext";
import { homeStore } from "../../contexts/HomeContext";
import { sessionStore } from "../../contexts/SessionContext";
import { errorStore } from "../../contexts/ErrorContext";
import { AuthMode, Config, Pagetype } from "../../interfaces/Config";
import { LoaderStatus } from "../../interfaces/Status";
import {
    Student,
    Subject,
    Teacher,
    TokenPayload,
    User,
    UserExtra,
    UserType,
} from "../../interfaces/User";
import { Data } from "../../interfaces/Data";
import { Home } from "../../interfaces/Home";
import { ContentPage } from "../../interfaces/ContentPage";
import useAthenaAPIClient from "../queries/useAthenaAPIClient/useAthenaAPIClient";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AIConfig } from "@evidenceb/ai-handler";
import { activateCustomColors } from "../../utils/custom-colors";
import axios from "axios";
import appConfig from "../../config";
import useSelectApp from "../useSelectApp";
import { featureFlagsStore } from "@evidenceb/athena-common/modules/FeatureFlags";
import { StatementHistory } from "../../interfaces/Session";
import { userToSession } from "../../utils/session";
import { getHost } from "../../utils/env";
import { ThemeType } from "../../interfaces/Theme";
import { PLACEHOLDER_SUBJECT } from "../useActiveSubject";
import { authMatch, redirectIfNotAuthPath } from "../../utils/auth";
import { mappingTreeToSubjects } from "../../utils/mapping";
import { useEventStreamEarlySubscribers } from "./useEventStreamEarlySubscribers";
import useSetSentryContext from "./useSetSentryContext";
import useInitServiceWorker from "./useInitServiceWorker";
import { getLocalStorageFlags } from "../../utils/flags";
import useAxiosClientInit from "./useAxiosClientInit";
import { useExercisesQuery } from "../queries/useExercisesQuery";
import { useAthenaEventsRecentlySynced } from "../../contexts/EventStreamContext";
import { Milliseconds } from "@evidenceb/athena-events-tools";
import { Module, Objective } from "@evidenceb/gameplay-interfaces";

export interface GlobalConfig {
    // TEMP
    config: Omit<Config, "ai"> & { ai: AIConfig };
    home: Home;
    contentPages: ContentPage[];
    theme: Config["theme"];
}

export default function useInitApp() {
    const { setData } = useContext(dataStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);
    const { config, setConfig } = useContext(configStore);
    const { session, setSession } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const { setFeatureFlags } = useContext(featureFlagsStore);
    const [status, setStatus] = useState<LoaderStatus>();
    const athenaAPIClient = useAthenaAPIClient();
    const selectApp = useSelectApp();

    // The following block of code, paired with the update to the
    // initAppAndRecentSyncRef at the end of the initApp function below, makes
    // the app initialization wait for a recent AthenaEvents sync with the
    // backend before completing. That means that if all other steps of the app
    // initialization process have completed, but the last AthenaEvents sync
    // occurred more than REQUIRED_SYNC_RECENCY milliseconds ago, the app waits
    // for another sync (which in the meantime has been kicked-off under the
    // hood) before completing initialization.
    //
    // This makes it so that the app always starts with a reasonably-recent set
    // of AthenaEvents in the local database.
    const REQUIRED_SYNC_RECENCY: Milliseconds = 4 * 60 * 60 * 1_000;
    const initAppAndRecentSyncRef = useRef({
        recentSync: false,
        initApp: false,
    });
    const recentSync = useAthenaEventsRecentlySynced(REQUIRED_SYNC_RECENCY);
    useEffect(() => {
        initAppAndRecentSyncRef.current.recentSync = recentSync;
        if (recentSync && initAppAndRecentSyncRef.current.initApp) {
            setStatus(LoaderStatus.Success);
        }
    }, [recentSync]);

    const axiosClientInitialized = useAxiosClientInit(status);

    useEventStreamEarlySubscribers();

    useInitServiceWorker();

    useExercisesQuery({
        enabled: status === LoaderStatus.Success,
    });

    useEffect(() => {
        let dsn;
        if (window.location.hostname.endsWith("miaseconde.fr")) {
            dsn =
                "https://3cbf506f4ab14f56864bbe44f792c687@glitchtip.mia-prod.evidenceb-services.com/7";
            // miaseconde is used in a more restricted environment that has its own matomo
        } else {
            dsn =
                "https://d6676273f92a44a49199264cc6cb98e3@sentry.evidenceb-services.com/4503924293894144";
        }
        //SENTRY INIT

        if (process.env.NODE_ENV !== "development")
            Sentry.init({
                dsn: dsn,
                autoSessionTracking: true,
                integrations: [
                    new Integrations.BrowserTracing({
                        tracingOrigins: [
                            "https://athena-auth",
                            "https://athena-content-access",
                            "https://analytics",
                            "https://athena-analytics",
                            "https://xapi",
                        ],
                    }),
                ],
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 1.0,
                environment: process.env.NODE_ENV,
            });
    }, []);

    useSetSentryContext();

    useEffect(() => {
        if (isSpecimenVersion()) {
            (async function initSpecimen() {
                const variation = await getSpecimenVariation();
                athenaAPIClient.setSpecimenVariation(variation);
            })();
        }
    }, [athenaAPIClient]);

    useEffect(() => {
        const setError = (code: string) => {
            setErrorInfo({
                displayModal: false,
                page: { code: code },
            });
            setStatus(LoaderStatus.Error);
        };

        if (!axiosClientInitialized || typeof status !== "undefined") return;
        if (isSpecimenVersion() && !athenaAPIClient.specimenVariation) return;

        setStatus(LoaderStatus.Loading);

        (async function initApp() {
            const isSpecimen = isSpecimenVersion();
            let specimenVariation: string | false = false;
            if (isSpecimen) specimenVariation = await getSpecimenVariation();
            setConfig((curr) => ({
                ...curr,
                specimenVariation,
            }));

            // Token resolution
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlToken = urlParams.get("token") as string;
            const localStorageToken = localStorage.getItem<string>(
                localStorage.Key.TOKEN
            );
            let token: string;
            let tokenPayload: TokenPayload;

            try {
                if (!isSpecimen) {
                    token = tokenResolver(urlToken, localStorageToken);
                    axiosClient.apiToken = token;
                    localStorage.setItem(localStorage.Key.TOKEN, token);
                }
                tokenPayload = await athenaAPIClient.getTokenPayload();

                // [TEMP] hotfix to prevent access to /auth route when the user is logged
                // should be removed when the router as a whole is fixed
                if (`${window.location.pathname}/`.startsWith("/auth/")) {
                    window.location.assign("/");
                    return;
                }
            } catch (error) {
                // 1st iteration of the "no token authentication", it will be reworked & improved at a later date when we recode the entire useInitApp
                // Check if app exists in applications.json and if it has a "AUTH_REQUIRED" authMode
                try {
                    const baseUrl =
                        window.location.origin + (appConfig.basePath ?? "/");
                    const { data } = await axios.get(
                        `${baseUrl}json/applications.json`
                    );
                    const { currentApp, config: applicationConfig } =
                        applicationResolver(
                            data,
                            getHost(),
                            window.location.search
                        );

                    const minimalConfigPath =
                        currentApp.globalConfig ??
                        applicationConfig?.globalConfig;
                    let redirects = false;
                    if (
                        authMatch(applicationConfig.authMode, [
                            AuthMode.DirectAccess,
                            AuthMode.Authentication,
                        ]) &&
                        minimalConfigPath
                    )
                        redirects = await redirectIfNotAuthPath(
                            config.apiUrls.endpoints,
                            minimalConfigPath
                        );
                    if (
                        !redirects &&
                        authMatch(
                            applicationConfig.authMode,
                            AuthMode.Authentication
                        ) &&
                        minimalConfigPath
                    ) {
                        try {
                            await selectApp(currentApp, applicationConfig);
                            setStatus(LoaderStatus.Success);
                            return;
                        } catch (err) {
                            // Fail to load minimal_globalConfig.json
                            // todo: rework error handling
                            setError("token");
                            Sentry.captureException({
                                error: error,
                                messsage:
                                    "ERROR: Fail to load minimal_globalConfig.json",
                            });
                            console.error(
                                "ERROR: Fail to load minimal_globalConfig.json"
                            );
                            return;
                        }
                    } else {
                        // app has no authMode = AUTH_REQUIRED
                        // todo: rework error handling
                        setError("token");
                        if (
                            !currentApp.globalConfig ||
                            !applicationConfig.availableApps
                        ) {
                            Sentry.captureException({
                                error: error,
                                messsage: "ERROR: no globalconfig path for app",
                            });
                            console.error(
                                "ERROR: no globalconfig path for app"
                            );
                        } else {
                            Sentry.captureException({
                                error: error,
                                messsage:
                                    "ERROR: app has no authMode = AUTH_REQUIRED",
                            });
                            console.error(
                                "ERROR: app has no authMode = AUTH_REQUIRED"
                            );
                        }
                        return;
                    }
                } catch (err) {
                    // Fail to load applications.json
                    // todo: rework error handling
                    setError("token");
                    Sentry.captureException({
                        error: err,
                        messsage: "initApp() caught error",
                    });
                    console.error("initApp() caught error: ", err);
                    return;
                }
            }

            let useHistoryFrom = StatementHistory.LRS;
            if (isSpecimen) {
                useHistoryFrom = StatementHistory.noHistory;
            }

            // Version resolution
            const urlVersion = urlParams.get("version") as string;
            const localStorageVersion = localStorage.getItem<string>(
                localStorage.Key.VERSION
            );
            let version: string;
            try {
                version = versionResolver(
                    tokenPayload,
                    urlVersion,
                    localStorageVersion
                );
                localStorage.setItem(localStorage.Key.VERSION, version);
            } catch (error) {
                version = ""; // If error, version variable needs to be assigned here so it can be a string type
                setError("version");
                return;
            }

            // Get user info
            const userType = tokenPayload.role.toUpperCase() as UserType;
            setSession((curr) => ({ ...curr, userType, version }));
            let user: Teacher | Student;
            try {
                user = await athenaAPIClient.getUser(tokenPayload);
            } catch (error) {
                setStatus(LoaderStatus.Error);
                return;
            }
            const userToSessionData = userToSession(user, userType);

            // Get GlobalConfig
            let globalConfig: GlobalConfig;
            let fragments = user.config.fragments_config ?? [];
            try {
                globalConfig = await athenaAPIClient.getGlobalConfig(
                    version,
                    fragments
                );
                setConfig((config) => {
                    return {
                        ...config,
                        lang: globalConfig.config.lang,
                    };
                });
            } catch (error) {
                setError("config");
                return;
            }

            // Resolve authentication
            let displaySignIn: boolean;
            try {
                displaySignIn = await shouldSignIn(
                    user,
                    globalConfig.config.auth.mode,
                    tokenPayload.role.toUpperCase() as UserType,
                    globalConfig.config.declinaison,
                    athenaAPIClient.getClassroom.bind(athenaAPIClient)
                );
                console.log(displaySignIn);
            } catch (error) {
                //TODO error details ?
            }

            // TEMP
            const tempGlobalconfig: Omit<GlobalConfig, "config"> & {
                config: Config;
            } = {
                ...globalConfig,
                config: {
                    ...globalConfig.config,
                    ai: globalConfig.config.ai,
                },
            };

            // Feature flags
            const mergedFlags = {
                ...tempGlobalconfig.config.features,
                ...tempGlobalconfig.config.releaseFlags,
                ...getLocalStorageFlags(),
            } as { [key: string]: any };
            mergedFlags.isSpecimen = isSpecimen;
            const flattenedFlags = flattenBooleansInObject(mergedFlags);
            setFeatureFlags(flattenedFlags);

            // Get data
            let data: Data;
            try {
                data = removeNonVisible(
                    await athenaAPIClient.getData(
                        version,
                        flattenedFlags[
                            "init-bandit-manchot-with-adaptive-test"
                        ],
                        "questions-no-content"
                    )
                );
                console.log("DATA", data);
            } catch (error) {
                setError("data");
                return;
            }

            // Register debug tools
            registerDebugUtils(data, athenaAPIClient);

            setSession((session) => {
                return {
                    ...session,
                    ...userToSessionData,
                    appVariation: globalConfig.config.declinaison,
                    evidencebId: tokenPayload.sub,
                    sessionId: uuid(),
                    theme: globalConfig.theme.type ?? ThemeType.Light,
                    flags: {
                        ...session.flags,
                        useHistoryFrom,
                        displaySignIn: displaySignIn,
                    },
                    specimen: isSpecimen,
                    school: user.school,
                };
            });
            setConfig((config) => merge({}, config, tempGlobalconfig.config));

            setHome(globalConfig.home);
            setContentPages(globalConfig.contentPages);
            applyThemeWithLegacy({ ...globalConfig.theme });

            if (globalConfig.config.features.customColors) {
                activateCustomColors(globalConfig.theme?.customColors ?? {});
            }
            let mappedModules: Module[] | undefined = undefined;
            let mappedObjectives: Objective[] | undefined = undefined;

            if (flattenedFlags.requireMapping) {
                try {
                    const mappingTree = await athenaAPIClient.getMappingTree();

                    if (mappingTree.id) {
                        setConfig((config) => ({ ...config, mappingTree }));

                        const learningEntries =
                            getLearningEntries(tempGlobalconfig);

                        if (learningEntries.length !== 0) {
                            try {
                                let learningSet: {
                                    [key: string]: Objective;
                                } = {};
                                await Promise.all(
                                    learningEntries.map(
                                        async (learningSetId) => {
                                            const newLearningSet =
                                                await athenaAPIClient.getLearningSet(
                                                    learningSetId!,
                                                    // Depth of objectives, in which lay mapping information
                                                    { depth: "3" }
                                                );
                                            Object.assign(
                                                learningSet,
                                                newLearningSet
                                            );
                                        }
                                    )
                                );
                                mappedModules = data.modules.map((mod) => ({
                                    ...mod,
                                    mapping_nodes:
                                        learningSet[mod.id]?.mapping_nodes,
                                }));
                                mappedObjectives = data.objectives.map(
                                    (obj) => ({
                                        ...obj,
                                        mapping_nodes:
                                            learningSet[obj.id]?.mapping_nodes,
                                    })
                                );
                            } catch (error) {
                                setError("data");
                                return;
                            }
                        }
                    }

                    const subjects = mappingTreeToSubjects(mappingTree);

                    let activeSubject: string | undefined;

                    if (userType === UserType.Teacher) {
                        activeSubject = await getActiveSubject(
                            user.id,
                            userType,
                            userToSessionData.extra ?? {},
                            subjects,
                            athenaAPIClient.updateUserExtra
                        );
                    } else activeSubject = subjects[0].id;
                    setSession((session) => ({
                        ...session,
                        extra: {
                            ...session.extra,
                            activeSubject: activeSubject,
                        },
                    }));
                } catch (error) {
                    setError("data");
                    return;
                }
            } else {
                setSession((session) => ({
                    ...session,
                    extra: {
                        ...session.extra,
                        activeSubject: PLACEHOLDER_SUBJECT.id,
                    },
                }));
            }

            setData({
                modules: mappedModules ?? data.modules,
                objectives: mappedObjectives ?? data.objectives,
                activities: data.activities,
                exercises: data.exercises,
            });

            initAppAndRecentSyncRef.current.initApp = true;
            if (
                initAppAndRecentSyncRef.current.recentSync ||
                // If there's no recent sync, but the user is offline, still set
                // the init status to Success. Otherwise, since the user is
                // offline, a sync will not occur, the init status will never be
                // set to Success, and the app will stay forever in the loading
                // state.
                !navigator.onLine
            ) {
                setStatus(LoaderStatus.Success);
            }
        })();
    }, [
        axiosClientInitialized,
        selectApp,
        config.apiUrls,
        setConfig,
        setContentPages,
        setData,
        setHome,
        setSession,
        athenaAPIClient,
        setErrorInfo,
        status,
        setFeatureFlags,
        config,
        config.mode,
        session.extra,
        session.evidencebId,
        session.userType,
    ]);

    return { status, setStatus };
}

export const getLearningEntries = (tempGlobalconfig: { config: Config }) => {
    const modulesLearningEntries =
        tempGlobalconfig.config.pages.find(
            (page) => page.type === Pagetype.MODULELIST
        )?.entryLearningSetIds ?? [];

    if (
        modulesLearningEntries.length === 0 &&
        tempGlobalconfig.config.mode?.["soloai"]
    )
        return [tempGlobalconfig.config.mode?.["soloai"]];
    return modulesLearningEntries;
};

const getActiveSubject = async (
    userId: string,
    userType: UserType,
    extra: UserExtra,
    subjects: Subject[],
    updateActiveSubject: (
        extra: UserExtra,
        userId: string,
        userType: UserType
    ) => Promise<User>
): Promise<string | undefined> => {
    // If activeSubject is already set to an existing value
    if (
        extra.activeSubject &&
        subjects.some((subj) => subj.id === extra.activeSubject)
    )
        return extra.activeSubject;

    const matchingExtraSubject = subjects.find((subj) =>
        extra.subjects?.includes(subj.id)
    );
    // If there is a matching value in the subjects returned by the GAR
    if (matchingExtraSubject) {
        try {
            await updateActiveSubject(
                { activeSubject: matchingExtraSubject.id },
                userId,
                userType
            );
            return matchingExtraSubject.id;
        } catch {
            return undefined;
        }
    }
    return undefined;
};
