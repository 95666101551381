import { defineMessages, IntlShape } from "react-intl";
import { Data } from "../interfaces/Data";
import { commonMessages } from "./messages";
import capitalize from "lodash/capitalize";
import {
    getActivityById,
    getModuleById,
    getObjectiveById,
    getResourceIndexFromData,
} from "./dataRetrieval";

export interface TimeI18n {
    hour: string;
    hours: string;
    minute: string;
    minutes: string;
    second: string;
    seconds: string;
}

export const timeI18n = (intl: IntlShape): TimeI18n => ({
    hour: intl.formatMessage(messages.hour),
    hours: intl.formatMessage(messages.hours),
    minute: intl.formatMessage(messages.minute),
    minutes: intl.formatMessage(messages.minutes),
    second: intl.formatMessage(messages.second),
    seconds: intl.formatMessage(messages.seconds),
});

/**
 * Formats a duration in h/m/s
 * @param duration in seconds
 */
export const formatDuration = (
    duration: number,
    timeI18n: TimeI18n
): string => {
    const h = Math.floor(duration / (60 * 60));
    duration -= h * 60 * 60;
    const m = Math.floor(duration / 60);
    const s = duration - m * 60;
    const hString = h ? `${h}${h > 1 ? timeI18n.hours : timeI18n.hour} ` : "";
    const mString =
        h || m ? `${m}${m > 1 ? timeI18n.minutes : timeI18n.minute} ` : "";

    return `${hString}${mString}${s}${
        s > 1 ? timeI18n.seconds : timeI18n.second
    }`;
};

const messages = defineMessages({
    hour: {
        id: "dashboard-studentProgression-timeFormat-hour",
        defaultMessage: "h",
    },
    minute: {
        id: "dashboard-studentProgression-timeFormat-minute",
        defaultMessage: "min",
    },
    second: {
        id: "dashboard-studentProgression-timeFormat-second",
        defaultMessage: "sec",
    },
    hours: {
        id: "dashboard-studentProgression-timeFormat-hours",
        defaultMessage: "h",
    },
    minutes: {
        id: "dashboard-studentProgression-timeFormat-minutes",
        defaultMessage: "mins",
    },
    seconds: {
        id: "dashboard-studentProgression-timeFormat-seconds",
        defaultMessage: "secs",
    },
});

export const monthI18n = (intl: IntlShape, month: string) => {
    return intl.formatMessage({ id: month, defaultMessage: month });
};

export const months = defineMessages({
    january: {
        id: "month-january",
        defaultMessage: "january",
    },
    february: {
        id: "month-february",
        defaultMessage: "february",
    },
    march: {
        id: "month-march",
        defaultMessage: "march",
    },
    april: {
        id: "month-april",
        defaultMessage: "april",
    },
    may: {
        id: "month-may",
        defaultMessage: "may",
    },
    june: {
        id: "month-june",
        defaultMessage: "june",
    },
    july: {
        id: "month-july",
        defaultMessage: "july",
    },
    august: {
        id: "month-august",
        defaultMessage: "august",
    },
    september: {
        id: "month-september",
        defaultMessage: "september",
    },
    october: {
        id: "month-october",
        defaultMessage: "october",
    },
    november: {
        id: "month-november",
        defaultMessage: "november",
    },
    december: {
        id: "month-december",
        defaultMessage: "december",
    },
});

export const pxToRem = (size: number): string => {
    return `${size / 16}rem`;
};

export const fullName = (
    name:
        | { firstname: string; lastname: string }
        | { first_name: string; last_name: string },
    opts: { startWithLastName?: boolean; capitalizeLastName?: boolean } = {
        startWithLastName: false,
        capitalizeLastName: false,
    }
): string => {
    const firstname = "firstname" in name ? name.firstname : name.first_name;
    let lastname = "lastname" in name ? name.lastname : name.last_name;
    if (opts?.capitalizeLastName) lastname = lastname.toUpperCase();
    if (opts?.startWithLastName) return `${lastname} ${firstname}`;
    return `${firstname} ${lastname}`;
};

export const initials = (name: {
    firstname: string;
    lastname: string;
}): string => name.firstname.charAt(0);

type Opts = Partial<{
    short: boolean;
}>;
type Input = string | (Opts & { id: string; numbering?: number | boolean });
type ResourceType = "module" | "objective" | "activity" | "exercise";
export const resourceTitle = (
    input: { [key in ResourceType]?: Input } & Opts,
    data: Data,
    intl: IntlShape
): {
    [Property in keyof Omit<
        typeof input,
        keyof Opts
    >]: (typeof input)[Property] extends undefined ? undefined : string;
} => {
    const output: any = {};

    const module = getInputInfo(input.module, input.short);
    const objective = getInputInfo(input.objective, input.short);
    const activity = getInputInfo(input.activity, input.short);
    const exercise = getInputInfo(input.exercise, input.short);

    let resourceIndex: number | undefined;

    if (module.id) {
        output["module"] = "";
        const mod = getModuleById(module.id, data);
        switch (typeof module.numbering) {
            case "boolean":
                resourceIndex = getResourceIndexFromData(
                    mod,
                    data.modules,
                    "module"
                );
                break;
            case "number":
                resourceIndex = module.numbering;
                break;
            default:
                resourceIndex = undefined;
        }

        if (module.short) {
            output["module"] += `${intl
                .formatMessage(commonMessages.module)
                .charAt(0)
                .toUpperCase()}${resourceIndex ?? ""}`;
        } else {
            output["module"] += `${resourceIndex ? `${resourceIndex}. ` : ""}${
                mod.title.short
            }`;
        }
    }

    if (objective.id) {
        output["objective"] = "";
        const obj = getObjectiveById(objective.id, data);
        switch (typeof objective.numbering) {
            case "boolean":
                resourceIndex = getResourceIndexFromData(
                    obj,
                    data.objectives,
                    "objective"
                );
                break;
            case "number":
                resourceIndex = objective.numbering;
                break;
            default:
                resourceIndex = undefined;
        }

        if (objective.short) {
            output["objective"] += `${intl
                .formatMessage(commonMessages.objective)
                .charAt(0)
                .toUpperCase()}${resourceIndex ?? ""}`;
        } else {
            const resourceType = capitalize(
                `${intl.formatMessage(commonMessages.objective)}`
            );

            output["objective"] += `${
                resourceIndex ? `${resourceType} ${resourceIndex}: ` : ""
            }${getObjectiveById(objective.id, data).title.short}`;
        }
    }

    let act;
    if (activity.id) {
        output["activity"] = "";
        act = getActivityById(activity.id, data);
        switch (typeof activity.numbering) {
            case "boolean":
                resourceIndex = getResourceIndexFromData(
                    act,
                    data.activities,
                    "activity"
                );
                break;
            case "number":
                resourceIndex = activity.numbering;
                break;
            default:
                resourceIndex = undefined;
        }
        if (activity.short) {
            output["activity"] += `${intl
                .formatMessage(commonMessages.activity)
                .charAt(0)
                .toUpperCase()}${resourceIndex ?? ""}`;
        } else {
            const resourceType = capitalize(
                `${intl.formatMessage(commonMessages.activity)}`
            );
            output["activity"] += `${
                resourceIndex ? `${resourceType} ${resourceIndex}: ` : ""
            }${getActivityById(activity.id, data).title.short}`;
        }
    }

    if (exercise.id) {
        output["exercise"] = "";
        switch (typeof exercise.numbering) {
            case "boolean":
                act = activity.id
                    ? getActivityById(activity.id, data)
                    : undefined;
                resourceIndex =
                    act && act.exerciseIds.findIndex((e) => e === exercise.id);

                break;
            case "number":
                resourceIndex = exercise.numbering;
                break;
            default:
                resourceIndex = undefined;
        }

        if (exercise.short) {
            output["exercise"] += `${intl
                .formatMessage(commonMessages.exercise)
                .charAt(0)
                .toUpperCase()}${
                typeof resourceIndex !== "undefined"
                    ? typeof exercise.numbering === "number"
                        ? resourceIndex
                        : resourceIndex + 1
                    : ""
            }`;
        } else {
            output["exercise"] += `${capitalize(
                intl.formatMessage(commonMessages.exercise)
            )}${
                typeof resourceIndex !== "undefined"
                    ? typeof exercise.numbering === "number"
                        ? " " + resourceIndex
                        : " " + (resourceIndex + 1)
                    : ""
            }`;
        }
    }

    return output as {
        [Property in keyof Omit<
            typeof input,
            keyof Opts
        >]: (typeof input)[Property] extends undefined ? undefined : string;
    };
};
const getInputInfo = (
    input: Input | undefined,
    globalShort?: boolean
): { id?: string; short?: boolean; numbering?: boolean | number } => {
    if (!input) return {};
    if (typeof input === "string")
        return {
            id: input,
            short: globalShort,
        };
    return {
        id: input.id,
        short: input.short ?? globalShort,
        numbering: input.numbering,
    };
};

export const resourceShortPath = (
    hierarchy: string[],
    data: Data,
    intl: IntlShape
): string => {
    const title = resourceTitle(
        {
            module: { id: hierarchy[3], numbering: true },
            objective: { id: hierarchy[2], numbering: true },
            activity: { id: hierarchy[1], numbering: true },
            exercise: { id: hierarchy[0], numbering: true },
            short: true,
        },
        data,
        intl
    );
    return `${title.module} ${title.objective} ${title.activity} ${title.exercise}`;
};

export const formatTxtToSlug = (str: string) => {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[\W_]+/g, "-")
        .toLowerCase()
        .replace(/^-+|-+$/g, "");
};

export const formatSlugToText = (str: string) => {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[\W-]+/g, "_")
        .toUpperCase()
        .replace(/^-+|-+$/g, "");
};

export const stringFirstLetter = (
    str: string,
    letterCase: { uppercase: boolean }
) => {
    const string = str.charAt(0);
    if (letterCase.uppercase) return string.toUpperCase();
    return string;
};
